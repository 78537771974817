import React, { useEffect } from "react";
import {
  Box,
  Flex,
  PrimaryButton,
  SystemProps,
  TertiaryButton,
  Text,
  TransparentButton,
} from "flicket-ui";
import { useRouter } from "next/router";

import { Icon, Row as StyledRow } from "~components";
import { formatDate, withPropagation } from "~lib";
import { EventKind, EventQuery } from "~graphql/sdk";
import { useOrganization } from "~hooks";
import useCaptureClick from "~telemetry/traces/useCaptureClick";
import {
  AttributeNames,
  InteractionNames,
} from "~telemetry/enums/AttributeNames";

type RowProps = Partial<EventQuery["event"]> &
  SystemProps & {
    bg?: any;
    isNonFunctional?: boolean;
    disableRedirectToEvent?: boolean;
  };

export const Row = ({
  id,
  title,
  venue,
  hasPublicRelease,
  startDate,
  endDate,
  eventKind,
  isNonFunctional = false,
  disableRedirectToEvent,
  ...props
}: RowProps) => {
  const router = useRouter();
  const isDigitalEvent = eventKind === EventKind.Digital;
  const { organization } = useOrganization();

  const navigateEvent = async () =>
    router.push("/events/[eventId]", `/events/${id}`);
  const navigateReservation = async () => {
    return router.push(
      "/events/[eventId]/reservation",
      `/events/${id}/reservation`
    );
  };

  useEffect(() => {
    void router.prefetch("/events/[eventId]", `/events/${id}`);
    void router.prefetch(
      "/events/[eventId]/reservation",
      `/events/${id}/reservation`
    );
  }, [router, id]);

  const captureClick = useCaptureClick({
    forwardToNest: true,
    eventId: id,
    pageName: "event-list-page",
  });

  const primaryButtonLabel = isDigitalEvent ? "Get Access" : "Buy Tickets";

  return (
    <StyledRow
      d="flex"
      justifyContent="space-between"
      alignItems="center"
      mb={{ _: 0, md: 3 }}
      px={{ _: 0, md: 2 }}
      pt={{ _: "6/4", md: 2 }}
      pb={{ _: "6/4", md: 2 }}
      {...(!isNonFunctional && { onClick: navigateEvent })}
      {...props}
      key={id}
    >
      <Flex alignItems="center" flex="1" minW={0}>
        <Flex
          mr={{ _: 2, md: 4 }}
          flexDir="column"
          minW={{ _: "80px", md: "auto" }}
          w={{ _: "80px", md: "115px" }}
        >
          {endDate &&
          formatDate(startDate as string, "dd") !==
            formatDate(endDate as string, "dd") ? (
            <Text
              color="N800"
              lineHeight="medium"
              fontWeight={{ _: "medium", md: "extraBold" }}
              fontSize={{ _: 2, md: 4 }}
            >
              {formatDate(startDate as string, "dd")} -{" "}
              {formatDate(endDate as string, "dd MMM")}
            </Text>
          ) : (
            <>
              <Text
                color="N800"
                lineHeight="medium"
                fontWeight={{ _: "medium", md: "extraBold" }}
                fontSize={{ _: 2, md: 4 }}
              >
                {formatDate(startDate as string, "dd MMM")}
              </Text>
              <Text
                color={{ _: "N800", md: "N600" } as any}
                mt={{ _: 0, md: "1/2" }}
                lineHeight={{ _: "18px" as any, md: "normal" }}
                fontSize={{ _: 2, md: 3 }}
                fontWeight={{ _: "medium", md: "regular" }}
                ellipsis
              >
                {formatDate(startDate as string, "E, p")}
              </Text>
            </>
          )}
        </Flex>
        <Flex
          flex="1"
          flexDir="column"
          maxW={{ _: "calc(100% - 96px)", md: "auto" }}
          minW={0}
          mr={!isNonFunctional ? 3 : { _: 0, md: 3 }}
        >
          <Text
            as={"h1"}
            color="N800"
            lineHeight="medium"
            fontWeight={{ _: "heavy", md: "extraBold" }}
            fontSize={{ _: 2, md: 4 }}
            textTransform={{ _: "uppercase", md: "none" }}
            maxW="100%"
          >
            {`${title}${isDigitalEvent ? " (Online Event)" : ""}`}
          </Text>
          <Text
            as={"h2"}
            color="N600"
            mt={{ _: 0, md: "1/2" }}
            lineHeight={{ _: "18px" as any, md: "normal" }}
            fontSize={{ _: 2, md: 3 }}
            fontWeight={{ _: "demiBold", md: "regular" }}
          >
            {`${venue?.name}${
              isDigitalEvent ? "" : `, ${venue?.address?.city}` ?? ""
            }`}
          </Text>
        </Flex>
      </Flex>
      {!isNonFunctional && (
        <Box width="24px" d={{ 0: "flex", md: "none" }}>
          <TransparentButton onClick={(e) => withPropagation(e, navigateEvent)}>
            <Icon fontSize={6} icon="chevron-right" color="P300" />
          </TransparentButton>
        </Box>
      )}
      <Box height="36px" d={{ 0: "none", md: "flex" }}>
        <TertiaryButton
          px={3}
          py="1/2"
          onClick={(e) =>
            !disableRedirectToEvent && withPropagation(e, navigateEvent)
          }
        >
          <Text fontSize={2} lineHeight={"16px" as any}>
            Event Information
          </Text>
        </TertiaryButton>
        {hasPublicRelease &&
          !disableRedirectToEvent &&
          organization.id !== "58106ce0-e1cd-47cb-9c59-0d0192ca6df8" &&
          organization.id !== "45339f71-c0bb-41a9-b642-7d30f1321a84" && (
            <PrimaryButton
              px={3}
              py="1/2"
              ml="6/4"
              onClick={captureClick(
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                (e) => withPropagation(e, navigateReservation),
                InteractionNames.BUTTON_PRIMARY,
                {
                  [AttributeNames.BUTTON_LABEL]: primaryButtonLabel,
                }
              )}
            >
              <Text fontSize={2} lineHeight={"16px" as any}>
                {primaryButtonLabel}
              </Text>
            </PrimaryButton>
          )}
      </Box>
    </StyledRow>
  );
};
